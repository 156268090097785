.formControl {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 1em;
}
.formControl label {
  position: absolute;
  border-radius: 5px;
  top: -7px;
  left: 5px;
  font-size: 0.8em;
  color: #bbbbbb;
}
.formControl input,
.formControl textarea {
  border-radius: 5px;
  border: none;
  padding: 1em;
  color: white;
  background-color: #3e3e3e;
  outline: none;
  resize: none;
  font: inherit;
}
.formControl input {
  border-radius: 5px;
  border: none;
  padding: 1em;
  background-color: #3e3e3e;
}
.modalContent {
  border-radius: 5px;
  background-color: #282828;
  padding: 1em;
  margin: 0 auto;
  min-width: 360px;
  max-width: 500px;
}
