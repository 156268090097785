::-webkit-scrollbar-track {
  background-color: #303030;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background-color: #6d6d6d;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a7a7a7;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  /* webpackIgnore: true */
  src: url(/fonts/inter-var-latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
